import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion/dist/framer-motion";
import { Helmet } from "react-helmet";
import LocationCard from "../Components/LocationCard";
import OhtCard from "../Components/OHTCard";
import AddLocation from "./AddLocation";
import SelectOHT from "./SelectOHT";

import UserService from "../../../../services/UserService";
import EditLocation from "./EditLocation";
import { ShowBottomBar, ShowTopBar } from "../../../../services/ToggleNativeBar";
import { Oht } from "../Types";

const PROFILE_FRAGMENT = "#referer=profile";

const ChangeLocation = () => {
  const [t] = useTranslation("translations");

  const [locations, setLocations] = useState(UserService.getAttribute("locations"));
  const [ohts, setOhts] = useState(UserService.getAttribute("ohts"));

  const MAX_LOCATIONS = locations.length >= 10;
  const MAX_OHTS = ohts.length >= 10;

  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    ShowTopBar(true);
    ShowBottomBar(false);
  }, []);

  let locationPath = "",
    ohtPath = "",
    referredFromProfile: Boolean;

  if (PROFILE_FRAGMENT === location.hash) {
    // go to edit location page
    referredFromProfile = true;
    locationPath = `/LOCATION_SELECTION/${EditLocation.name}`;
    ohtPath = `/LOCATION_SELECTION/${SelectOHT.name}`;
  } else {
    // select location page
    referredFromProfile = false;
    locationPath = "/";
    ohtPath = "/";
  }

  function onDeleteLocation(index: number) {
    const newItems = [...locations];
    newItems.splice(index, 1);

    setLocations(newItems);
    UserService.setAttribute("locations", newItems);

    // Removes savedLocationOht key if a user deletes the current one they have selected
    if (UserService.getAttribute("savedLocationOht").id === locations[index].id) {
      UserService.removeAttribute("savedLocationOht");
    }
  }

  function onDeleteOht(index: number) {
    const newItems = [...ohts];
    newItems.splice(index, 1);

    setOhts(newItems);
    UserService.setAttribute("ohts", newItems);

    // Removes savedLocationOht key if a user deletes the current one they have selected
    if (UserService.getAttribute("savedLocationOht").id === ohts[index].id) {
      UserService.removeAttribute("savedLocationOht");
    }
  }


  function onClickOht(oht: Oht) {
    if (oht.isDefault || oht.isDemo) {
      UserService.removeAttribute(
        "savedLocationOht"
      );
    } else if (!(referredFromProfile)) {
      UserService.setAttribute(
        "savedLocationOht",
        oht
      );
    }
    history(ohtPath);
  }


  return (
    <div className="ls__page">
      <Helmet>
        <title>{t("LocationSelection.ChangeLocation.page_title")}</title>
      </Helmet>
      <motion.div className="ls__changesection">
        <p className="ls__pageheader">{t("LocationSelection.ChangeLocation.locations_header")}</p>
        <>
          {locations.length > 0 ? (
            locations.map(({ distance, id, address, position }, index) => {
              const { postalcode, city } = address;
              return (
                <LocationCard
                  onClick={() => {
                    if (referredFromProfile) {
                      history(`${locationPath}#id=${id}`);
                    } else {
                      UserService.setAttribute("savedLocationOht", {
                        address,
                        distance,
                        id,
                        position,
                        type: "location",
                      });

                      history(locationPath);
                    }
                  }}
                  main={city}
                  secondary={postalcode}
                  distance={`${distance}km`}
                  key={id}
                  index={index}
                  onDelete={onDeleteLocation}
                />
              );
            })
          ) : (
            <div className="ls__changesection__empty">
              {t("LocationSelection.ChangeLocation.locations--empty")}
            </div>
          )}
        </>
        <button
          onClick={() => {
            history(`/LOCATION_SELECTION/${AddLocation.name}`);
          }}
          className={`ls__add ${MAX_LOCATIONS ? "ls__add--disabled" : ""}`}
          disabled={MAX_LOCATIONS}
        >
          {t("LocationSelection.ChangeLocation.add")}
        </button>
      </motion.div>
      <motion.div layout className="ls__changesection">
        <p className="ls__pageheader">{t("LocationSelection.ChangeLocation.ohts_header")}</p>
        {ohts.length > 0 ? (
          ohts.map(({ name, description, image, id, ...rest }, index) => (
            <OhtCard
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                !referredFromProfile &&
                  UserService.setAttribute("savedLocationOht", {
                    type: "oht",
                    name,
                    description,
                    image,
                    id,
                    ...rest,
                  });
                history(ohtPath);
              }}
              main={name}
              secondary={description}
              image={image}
              key={id}
              index={index}
              onDelete={onDeleteOht}
            />
          ))
        ) : (
          <div className="ls__changesection__empty">
            <p className="ls__changesection__empty">
              {t("LocationSelection.ChangeLocation.ohts--empty-1")}
            </p>
            <p className="ls__changesection__empty">
              {t("LocationSelection.ChangeLocation.ohts--empty-2")}
            </p>
          </div>
        )}
        <button
          onClick={() => {
            history(`/LOCATION_SELECTION/${SelectOHT.name}`);
          }}
          className={`ls__add ${MAX_OHTS ? "ls__add--disabled" : ""}`}
          disabled={MAX_OHTS}
        >
          {t("LocationSelection.ChangeLocation.add")}
        </button>
      </motion.div>
    </div>
  );
};

export default ChangeLocation;
