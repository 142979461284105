// Components
import RowItems from "./RowItem";

// Utils
import { handleOverrideClassName } from "../../utils/CommonFunctions";
import addTile from "./rowHelper";

// Interfaces
import { theme as themeType, contentRow } from "../../ComponentInterface";

interface Props {
  theme: themeType;
  pageContent: contentRow;
  pageName?: string;
}

const RegularRow = ({ pageContent, theme, pageName }: Props) => {
  const allItems = pageContent?.items ?? [];
  const itemsToDisplay = pageContent?.additional_tile ? addTile(pageContent, allItems) : allItems;

  if (!itemsToDisplay.length) return null;

  return (
    <>
      {(pageContent?.items) && (
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0}
          className={`row-container ${handleOverrideClassName(pageContent.rowContainerClassname)}`}
        >
          {itemsToDisplay.map((item, i) => (
            <RowItems key={i} rowItem={item} theme={theme} pageName={pageName} />
          ))}
        </div>
        
      )}
    </>
  )
};

export default RegularRow;
